<template>
  <MainTable :table-props="tableProps">
    <thead class="thead-table-paranje">
      <tr>
        <th class="uk-table-expand">
          <span class="uk-text-bold">Kode Barang</span>
        </th>
        <th class="uk-table-expand">
          <span class="uk-text-bold">Nama Barang</span>
        </th>
        <th class="uk-width-small">
          <span class="uk-text-bold">Satuan</span>
        </th>
        <th class="uk-table-expand">
          <span class="uk-text-bold">Harga Beli</span>
        </th>
        <th class="uk-table-expand">
          <span class="uk-text-bold">Terakhir Update</span>
        </th>
        <th class="uk-table-expand uk-text-center">
          <span class="uk-text-bold">Aksi</span>
        </th>
      </tr>
    </thead>
    <template v-if="!is_loading">
      <tbody v-if="ovkCategories.meta.total_count>0">
        <tr
          v-for="(farm, i) in ovkCategories.data"
          :key="i"
        >
          <td>{{ farm.code || '-' }}</td>
          <td>{{ farm.name || '-' }}</td>
          <td>{{ farm.unit_name || '-' }}</td>
          <td>{{ getPrice(farm.price) || '-' }}</td>
          <td>{{ formatDate(farm.updated_at) || '-' }}</td>
          <td class="uk-flex uk-flex-center">
            <img
              v-if="isCanAccessUser(`view`, `Jenis OVK`)"
              v-lazy="`${images}/icon/eye.svg`"
              alt=""
              class="uk-margin-small-left img-click"
              @click="showDetail(farm.id)"
            >
          </td>
        </tr>
      </tbody>
      <empty-table
        v-else
        :colspan="6"
      />
    </template>
    <template v-else>
      <loading-table :colspan="6" />
    </template>
  </MainTable>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import MainTable from '@/components/globals/table'
import { dateUtcParanjeString, currencyIDR } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    MainTable
  },
  data() {
    return {
      is_loading: true,
      images: PREFIX_IMAGE,
      tableProps: {
        total_count: 0
      }
    }
  },
  computed : {
    ...mapGetters({
      ovkCategories: 'ovkCategory/ovkCategories',
      meta: 'ovkCategory/meta'
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getOvkCategory({...this.meta, by_item_category: 'ovk'})
      this.is_loading = false
    },
    ovkCategories() {
      if (this.ovkCategories) {
        this.tableProps ={
          ...this.meta,
          meta: this.meta,
          setMeta: this.setMeta,
          total_count: this.ovkCategories.meta.total_count
        }
      }
    }
  },
  async mounted() {
    await this.getOvkCategory({...this.meta, by_item_category: 'ovk'})
    this.is_loading = false
  },
  methods: {
    ...mapActions({
      getOvkCategory: 'ovkCategory/getOvkCategory'
    }),
    ...mapMutations({
      setMeta: 'ovkCategory/SET_META'
    }),
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    getPrice(code) {
      return currencyIDR(code)
    },
    showDetail(id) {
      this.$router.push(`/admin/jenis-ovk/detail/${id}`)
    }
  }
}
</script>
